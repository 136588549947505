import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components

import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
// import { Button } from '@jackywxd/shared-material-ui';
import { Parallax } from '@jackywxd/shared-material-ui';

import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';
import Button from 'components/CustomButtons';
import ServerLocation from './Sections/ServerLocation';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import {
  getRegionStart,
  setRegion,
} from '@allconnect/store/lib/user/user.actions';
import { selectRegion } from '@allconnect/store/lib/user/user.selectors';
import { isDesktop } from 'components/Utils';
// const dashboardRoutes = [];
import { Regions } from '@allconnect/models/lib/regions';

const regionMarkers = Regions.allMarkers();
const useStyles = makeStyles(landingPageStyle);

function ServerPage({ currentUser }) {
  const classes = useStyles();
  return (
    <Layout>
      <Parallax filter image={require('assets/img/servers-bg.jpg')}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.title} style={{ color: '#fff' }}>
                <FormattedMessage
                  id="server.title"
                  defaultMessage="Global Coverage"
                />
              </h1>
              <h3 style={{ color: '#fff' }}>
                <FormattedMessage
                  id="server.subtitle"
                  defaultMessage="Access public servers located in 13 regions across three continents. Or get a private server exclusively for you only!"
                />
              </h3>
              <br />
              <Button
                color="google"
                size="lg"
                round
                target="_blank"
                onClick={() => {
                  navigate('/register');
                }}
              >
                {currentUser ? (
                  <FormattedMessage
                    id="server.myaccount"
                    defaultMessage="My Account"
                  />
                ) : (
                  <FormattedMessage
                    id="button.registerFreeAccount"
                    defaultMessage="Register Free Account"
                  />
                )}
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={isDesktop() ? classes.container : null}>
          <ServerLocation />
          {/* <NetworkAnalyzer /> */}
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentRegion: selectRegion,
});

const mapDispatchToProps = dispatch => ({
  getRegionStart: () => dispatch(getRegionStart()),
  setRegion: city => {
    regionMarkers.map(region => {
      if (region.name === city) {
        dispatch(setRegion(region.region));
      }
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ServerPage);
